<template>
  <v-card
    class="
      py-2
      d-flex
      flex-column flex-sm-row
      justify-space-between
      align-center
      mb-2
      search-bar
      mx-auto
    "
    flat
    tile
  >
    <v-card class="pv-3 pr-0 pr-md-4" :class="verifySubTertiaryInput()" elevation="0" tile>
      <span>Filtro</span>
    </v-card>
    <v-card class="pa-1" elevation="0" tile>
      <v-card
        class="d-flex flex-column flex-md-row justify-space-between align-center ma-0 pa-0"
        flat
        tile
      >
        <v-card class="d-flex justify-center mb-4 flex-wrap flex-md-nowrap mb-md-0">
          <v-card
            v-if="hasTextInput"
            class="pa-0 mb-4 mb-md-0 custom-card d-flex align-center"
            :class="verifySubTertiaryInput()"
            elevation="0"
            :style="customSizeInput"
            tile
          >
            <simple-text-field
              prepend-inner-icon="mdi-magnify"
              class="mr-2"
              placeholder="Pesquisar"
              v-model="filters.text"
              height="0"
            />
          </v-card>

          <v-card
            v-if="!noSecondaryInput"
            class="pa-0  mb-4 mb-md-0 custom-secondary-input"
            :class="verifySubTertiaryInput()"
            elevation="0"
            :style="customSizeInput"
            tile
          >
            <slot></slot>
          </v-card>

          <v-card
            v-if="!noTertiaryInput"
            class="pa-0 mb-4 mb-md-0 custom-tertiary-input"
            elevation="0"
            :style="customSizeInput"
            tile
          >
            <slot name="tertiary-input"></slot>
            <slot v-if="!noSubTertiaryInput" name="sub-tertiary-input"></slot>
          </v-card>

          <v-card
            v-if="!noFourthInput"
            class="pa-0"
            :class="verifySubTertiaryInput()"
            elevation="0"
            :style="customSizeInput"
            tile
          >
            <slot name="fourth-input"></slot>
          </v-card>

          <v-card
            v-if="!noQuaternaryInput"
            class="pa-0 custom-tertiary-input"
            elevation="0"
            :style="customSizeInput"
            tile
          >
            <slot name="quaternary-input"></slot>
          </v-card>
        </v-card>
        <v-card
          class="pa-0"
          :class="verifySubTertiaryInput()"
          elevation="0"
          tile
        >
          <text-button
            v-if="hasClearFilters"
            color="black"
            :noMargin="true"
            @callAction="clearFilters()"
            label="Limpar filtro"
          />

          <primary-button
            v-if="hasActionButton"
            :label="actionButtonLabel"
            @callAction="callAction()"
          />
        </v-card>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import SimpleTextField from "../inputs/SimpleTextField";
import TextButton from "../buttons/TextButton";
import PrimaryButton from "../buttons/PrimaryButton";


export default {
  name: "SearchBar",
  components: { TextButton, SimpleTextField, PrimaryButton },
  props: {
    value: {
      type: Object,
      default: ()=>{},
    },
    hasTextInput: {
      type: Boolean,
      default: true,
    },
    noSecondaryInput: {
      type: Boolean,
      default: true,
    },
    noTertiaryInput: {
      type: Boolean,
      default: true,
    },

    noQuaternaryInput: {
      type: Boolean,
      default: true,
    },

    noSubTertiaryInput: {
      type: Boolean,
      default: true,
    },

    noFourthInput: {
      type: Boolean,
      default: true,
    },

    hasClearFilters: {
      type: Boolean,
      default: true,
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonLabel: {
      type: String,
      default: "",
    },
    smallFields: {
      type: Boolean,
    }
  },
  computed: {
    filters: {
      get() {
        return this.value ?? {};
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    customSizeInput: function () {
      let width = {};
      
      if(this.smallFields)
        return

      if (!this.noTertiaryInput) { //If has Tertiary input
        if(this.$vuetify.breakpoint.lgAndUp)
          width = { width: "250px" };
        else
          width = { width: "200px" };
      }
      else
        width = { width: "300px" };

      return width;
    },
  },
  methods: {
    clearFilters() {
      this.filters = {};
      this.$emit("externalFiltersClear");
    },
    callAction() {
      this.$emit("externalAction");
    },
    verifySubTertiaryInput() {
      return !this.noSubTertiaryInput && "spacing-bottom";
    },
  },
};
</script>
<style scoped>
.search-bar {
  border: solid 1px #febc00;
  border-radius: 25px !important;
  padding: 15px 35px;
}
.search-bar span {
  font-family: "PT Sans", sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #212020;
}
.search-bar .custom-text-button {
  margin-left: 0px !important;
}

::v-deep .custom-tertiary-input .custom-input input {
  padding: 0px !important;
}


.spacing-bottom {
  margin-bottom: auto;
}
</style>