<template>
  <div>
    <plot-records-table class="crud-data-table"></plot-records-table>
  </div>
</template>

<script>
import PlotRecordsTable from "./PlotRecordsTable";

export default {
  name: "PlotRecords",
  components: {
    PlotRecordsTable,
  },
};
</script>
