import http from "@/services/http.service";

const apiUrl = "/producers";

class ProducersService {
  static all() {
    return http.get(`${apiUrl}-all`);
  }

  static getProducersByAS(successAdvisorId) {
    return http.get(`${apiUrl}/as/${successAdvisorId}`);
  }

  static getProducerByUserId(userId) {
    return http.get(`${apiUrl}/user/${userId}`);
  }

  static updateProducersAs(newAs, oldAs) {
    return http.put(`${apiUrl}/as/new/${newAs}/old/${oldAs}`);
  }

  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }
}

export default ProducersService;
