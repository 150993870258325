import PlotRecordsService from "./plot-records.service";

class PlotRecord {
  constructor(payload = {}) {
    Object.assign(this, {
      ...PlotRecord.build(payload)
    });
  }

  static build(
    {
      id = '',
      producer_id = '',
      farm_id = '',
      harvest = '',
      created_at = '',
      updated_at = '',
      average_productivity = '',
      farm = {},
      producer = {},
      lots = []
    }
  ) {
    return {
      id,
      producer_id,
      farm_id,
      harvest,
      created_at,
      updated_at,
      average_productivity,
      farm,
      producer,
      lots
    }
  }

  static newData(payload) {
    return new PlotRecord(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .list(params)
        .then(
          success => {
            const data = { ...success };
            const list = Object.assign({}, { data });
            list.data = data.data.data.map(item => this.newData(item));
            resolve(list);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .create(payload)
        .then(
          success => {
            resolve(success);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static edit(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .edit(payload)
        .then(
          success => {
            resolve(success);
          },

          error => {
            reject(error);
          }
        );
    })
  }

  static get(plotRecordId) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .get(plotRecordId)
        .then(
          success => {
            const plotRecord = this.newData(success.data.data);
            resolve(plotRecord);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .delete(payload)
        .then(
          success => {
            resolve(success);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static getMap(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getMap(payload)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getKML(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getKML(payload)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getRankingNational(harvest) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getRankingNational(harvest)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getRankingRegional(harvest, successAdvisorId) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getRankingRegional(harvest, successAdvisorId)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getRankingState(harvest, stateId) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getRankingState(harvest, stateId)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getFarmRankingNational(farmId, harvest) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getFarmRankingNational(farmId, harvest)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getFarmRankingRegional(farmId, harvest) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getFarmRankingRegional(farmId, harvest)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getFarmRankingState(farmId, harvest) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getFarmRankingState(farmId, harvest)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error);
          }
        )
    })
  }

  static getHistory(farmId, payload, filters) {
    return new Promise((resolve, reject) => {
      PlotRecordsService
        .getFarmHistory(farmId, payload, filters)
        .then(
          success => {
            resolve(success)
          },

          error => {
            reject(error)
          }
        )
    });
  }
}

export default PlotRecord;
