import ProducersService from "./producers.service";

class Producer {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Producer.build(payload),
    });
  }

  static build({
    id = "",
    producer_id = "",
    user_id = "",
    user = {},
    is_active = "",
    cpf = "",
    farms = [],
    responsible_success_advisor = {},
  }) {
    return {
      id,
      producer_id,
      user_id,
      user,
      cpf,
      farms,
      responsible_success_advisor,
      is_active,
    };
  }

  static newData(payload) {
    return new Producer(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
      ProducersService.all().then(
        (success) => {
          const producers = success;
          producers.data = producers.data.map((item) => this.newData(item));
          resolve(producers);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getProducersByAS(successAdvisorId) {
    return new Promise((resolve, reject) => {
      ProducersService.getProducersByAS(successAdvisorId).then(
        (success) => {
          const producers = success;
          producers.data = producers.data.map((item) => this.newData(item));
          resolve(producers);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getProducerByUserId(userId) {
    return new Promise((resolve, reject) => {
      ProducersService.getProducerByUserId(userId).then(
        (success) => {
          const producer = success;
          producer.data = this.newData(producer.data.data);
          resolve(producer);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static updateProducersAs(newAs, oldAs) {
    return new Promise((resolve, reject) => {
      ProducersService.updateProducersAs(newAs, oldAs).then((success) => {
        resolve(success);
      }, (error) => {
        reject(error);
      });
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      ProducersService.delete(payload).then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default Producer;
