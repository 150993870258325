<template>
  <v-text-field
      v-if="mask"
      v-model="currentValue"
      dense
      hide-details="auto"
      outlined
      class="custom-input"
      :type="password ? 'password' : type"
      :required="required"
      :rules="getRules"
      :placeholder="placeholder"
      :height="height"
      :prepend-inner-icon="prependInnerIcon"
      :disabled="disabled"
      @input="onlyNumbers"
      @change="onlyNumbers"
      @keyup="getKeyUp()"
      v-mask="getMask()"
      :suffix="suffix"
  />
  <v-text-field
      v-else
      v-model="currentValue"
      dense
      hide-details="auto"
      outlined
      class="custom-input"
      :type="password ? 'password' : type"
      :required="required"
      :rules="getRules"
      :placeholder="placeholder"
      :height="height"
      :prepend-inner-icon="prependInnerIcon"
      :disabled="disabled"
      @input="onlyNumbers"
      @change="onlyNumbers"
      @keyup="getKeyUp()"
      :suffix="suffix"
  />
</template>

<script>
export default {
  name: "SimpleTextField",
  props: {
    value: {
      required: true,
    },
    password: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '48px',
    },
    mask: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ''
    },
    onlyPositiveNumber: {
      type: Boolean,
      default: false
    },
    suffix :{
      type: String,
      default:""
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value ?? "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getRules() {
      const rules = [];
      if (this.required) rules.push(this.rulesNative.required);
      if (this.value && this.email) rules.push(this.rulesNative.validMail);
      if (this.onlyPositiveNumber) rules.push(this.rulesNative.onlyPositiveNumber);
      return rules;
    },
  },
  data() {
    return {
      isValid: true,
      rulesNative: {
        required: (value) => !!value || "O campo é obrigatório",
        validMail: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          this.isValid = pattern.test(this.value);
          return pattern.test(value) || "Formato de e-mail inválido";
        },
        onlyPositiveNumber: (value) => (value >= 0) || "Apenas valores positivos"
      },
    };
  },
  methods: {
    onlyNumbers() {
      this.$emit('onlyNumbers');
    },

    getMask() {
      switch (this.mask) {
        case 'phone':
          return ['(##) ####-####', '(##) #####-####'];
        case 'cpf':
          return ['###.###.###-##'];
        case 'zip':
          return ['#####-###'];
        case 'harvest':
          return ['####'];
        case 'index':
          return ['########'];
      }
      return null
    },

    getKeyUp() {
      this.$emit('custom-key-up');
    }
  }
};
</script>

<style>
.custom-input {
  border-radius: 15px !important;
}

.custom-input input {
  padding: 0 10px !important;
}

.custom-input fieldset {
  border: 1px solid #e3e3e3;
  border-color: #FEBC00 !important;
}
</style>