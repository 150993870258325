<template>
  <div>
    <v-toolbar class="mb-8" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registro de Lotes
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <primary-button v-if="authoritySlug === 'super-admin' || permissions.access_cultivate" label="+ Adicionar"
        @callAction="addPlotRecordFarm" />
    </v-toolbar>

    <search-bar v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="7">
            <simple-select :items="cultivars" v-model="cultivarSearch" placeholder="Cultivares"
              @input="filterByCultivar" height="0" itemText="name" returnObject
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }" />
          </v-col>
          <v-col cols="12" md="5" class="pl-0">
            <simple-select :items="filteredPlotRecords.map(item => { return item.harvest })" v-model="harvestSearch"
              placeholder="Safra" @input="filterByHarvest" height="0" returnObject
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }" />
          </v-col>
        </v-row>
      </v-container>
    </search-bar>

    <!-- Data Table do registro de lote -->
    <v-tabs
      v-if="fetchingCultures"
      fixed-tabs
      background-color="#e3e3e3"
      ligth
      hide-slider
      class="font-weight-bold"
    >
      <v-tab>
        <v-progress-circular
        indeterminate
        color="primary"
        ></v-progress-circular>
      </v-tab>
    </v-tabs>

    
    <v-tabs
    v-else
    fixed-tabs
    background-color="#e3e3e3"
    ligth
    v-model="selectedCulture"
    class="font-weight-bold"
    @change="changeCulture()"
    >
      <v-tab 
        v-for="culture in cultures" :key="`culture${culture.id}`"
        :value="culture.name">
        {{culture.name}}
      </v-tab>     
    </v-tabs>

    <data-table :headers="headers" :fetching="fetching" :data="filteredPlotRecords" :objectName="objectName"
      :search="search.text" @loadAgain="loadAgain">
      <template v-slot:[`item.lots`]="{ item }">
        {{ getCultivarsFromLots(item.lots) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="viewItem(item)">
          mdi-information-outline
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_cultivate" class="ml-4" small
          @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <plot-record-farm-add v-if="showPlotRecordFarmAdd" :dialog="showPlotRecordFarmAdd" :producers="producers"
      @closeDialog="reset()" />

    <confirm-destroy-dialog :title="'este Registro de Lote'" :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog" />
  </div>
</template>

<script>
import PlotRecords from "@/domain/records/plot-records/plot-records";
import Producers from "@/domain/producers/producers";
import Cultivars from "@/domain/cultivars/cultivars";
import Cultures from "@/domain/cultures/cultures"
import Farms from "@/domain/farms/farms";

import PlotRecordFarmAdd from "./PlotRecordFarmAdd.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import DataTable from "@/components/data-table/DataTable";

import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import { getItem } from '@/services/local-storage.service';

export default {
  name: "PlotRecordsTable",
  components: {
    PlotRecordFarmAdd,
    PrimaryButton,
    SearchBar,
    SimpleSelect,
    DataTable,
    ConfirmDestroyDialog
  },
  data() {
    return {
      payload: {},

      plotRecordService: null,
      producerService: null,
      cultivarsService: null,
      farmService: null,
      plotIndexService: null,
      culturesService: null,

      plotRecords: [],
      producers: [],
      selectedProducer: [],
      cultivars: [],
      cultures: [],
      filteredPlotRecords:[],

      selectedCulture:0,

      dialog: false,
      showPlotRecordFarmAdd: false,
      search: {},
      harvestSearch: "",
      cultivarSearch: "",
      fetching: true ,
      fetchingCultures: true,
      objectName: 'registro de lotes',
      showConfirmDestroyDialog: false,

      authoritySlug: "",
      permissions: ""
    }
  },

  computed: {
    headers() {
      if(this.authoritySlug !== 'producer')
      return [
        { text: "Fazenda", value: "farm.name" },
        { text: "Proprietário", value: "producer.user.name" },
        {
          text: "Cultivares",
          value: "lots",
          filter: value => {
            if (!this.cultivarSearch) return true
            const response = value.map(item => item.cultivar.name === this.search.cultivar);
            return response.includes(true);
          }
        },
        {
          text: "Safra",
          value: "harvest",
          filter: value => {
            if (!this.harvestSearch) return true
            return value === this.search.harvest;
          }
        },
        { text: "Produtividade Média", value: "average_productivity" },
        { text: "Detalhes", value: "actions", sortable: false },
      ];
      else return [
        { text: "Fazenda", value: "farm.name" },
        {
          text: "Cultivares",
          value: "lots",
          filter: value => {
            if (!this.cultivarSearch) return true
            const response = value.map(item => item.cultivar.name === this.search.cultivar);
            return response.includes(true);
          }
        },
        {
          text: "Safra",
          value: "harvest",
          filter: value => {
            if (!this.harvestSearch) return true
            return value === this.search.harvest;
          }
        },
        { text: "Produtividade Média", value: "average_productivity" },
        { text: "Detalhes", value: "actions", sortable: false },
      ]
    },
  },

  methods: {
    async loadPlotRecords(params) {
      this.fetching = true;
      this.plotRecords = [];
      this.cultivars = [];

      if (this.authoritySlug === "producer") {
        this.selectedProducer = Number(await getItem("producerId"));

      }


      await this.plotRecordService.list(params).then(result => {
        if (this.authoritySlug === "producer") {
         this.plotRecords = result.data.filter(plotRecord => plotRecord.producer_id ===  this.selectedProducer)
        }else{
          result.data.map(item => {
            this.plotRecords.push(item);
          })
        }
      })
        .catch(({ response }) => {
          this.fetching = false;
          const { error } = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

      this.plotRecords.map(plotRecord => {
        if (plotRecord.lots.length > 0) {
          plotRecord.lots.map(lot => {
            this.cultivars.push(lot.cultivar.name);
          });
        }
      });


      this.fetching = false;
    },

    async loadCultures(){
      try{
        const { data } = await this.culturesService.all()
        
        this.cultures = data
        this.selectedCulture = this.cultures[0];        
      }catch(err){
        this.$toasted.show(err.response, {
            type: "error",
          });
      }finally{         
        this.fetchingCultures = false;
      }
    },

    loadProducers() {
      const loader = this.$loading.show();
      this.producers = [];

      this.producerService.all().then(result => {
        result.data.map(item => {
          this.producers.push(item);
        });
      });

      loader.hide();
    },

    changeCulture(){
      const culture = this.cultures[this.selectedCulture]
      let filtered = [];
      this.filteredPlotRecords = [];

      filtered = this.plotRecords.map(record=>{
        const newRecord = {...record}

        newRecord.average_productivity = record.average_productivity ? 
          record.average_productivity[culture.name] ? 
          (record.average_productivity[culture.name].average_productivity).toFixed(1)  : '--' :'--'

        return(newRecord);
      })

      this.filteredPlotRecords = filtered.sort((a,b)=>{
        const valueA = a.average_productivity;
        const valueB = b.average_productivity;
        
        if(typeof(valueA) === 'string')  return 1
        if(typeof(valueB) === 'string')  return -1
        return valueB - valueA
      })
    },

    getCultivarsFromLots(lots) {
      let cultivars = lots.map(item => {
        return item.cultivar.name;
      });

      let cultivarsUnique = cultivars.filter((item, pos) => {
        return !cultivars.includes(item, pos + 1);
      });

      cultivarsUnique = cultivarsUnique.join(',');
      const size = 20;

      if (cultivarsUnique.length <= size) {
        return cultivarsUnique;
      }

      return cultivarsUnique.substr(0, size) + "...";
    },

    async initialize() {
      this.payload = PlotRecords.newData();
      this.plotRecordService = PlotRecords;
      this.producerService = Producers;
      this.cultivarsService = Cultivars;
      this.farmService = Farms;
      this.culturesService = Cultures;

      this.authoritySlug = getItem('authoritySlug');
      this.permissions = JSON.parse(getItem('permissions'));

      await Promise.all([
        this.loadPlotRecords(),
        this.loadCultures()
      ]);
      
      this.changeCulture();
    },

    viewItem(item) {
      this.$router.push({
        name: 'plot-record-details',
        params: { plotRecordId: item.id }
      });
    },

    addPlotRecordFarm() {
      this.showPlotRecordFarmAdd = true;
      this.loadProducers();
    },

    reset() {
      this.dialog = false;
      this.showPlotRecordFarmAdd = false;
      this.loadPlotRecords();
    },

    filterByCultivar() {
      this.search.cultivar = this.cultivarSearch;
    },

    filterByHarvest() {
      this.search.harvest = this.harvestSearch;
    },

    clearFilters() {
      this.cultivarSearch = null;
      this.harvestSearch = null;
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    deleteItem(item) {
      this.payload = item;
      this.showConfirmDestroyDialog = true;
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.plotRecordService.delete(this.payload).then(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.loadPlotRecords();
        this.$toasted.show("Registro de lote removido com sucesso.", {
          type: "success",
        });
      })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Registro de lote não foi removido.", {
            type: 'error'
          });
        });
    },

    loadAgain() {
      this.initialize();
    }
  },

  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
::v-deep .col-farm .v-toolbar__content {
  padding: 0;
}
</style>
