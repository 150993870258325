<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text"
          >Novo Registro de Lote</span
        >
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="save">Salvar</v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col cols="12" md="8" class="col-farm">
              <v-row>
                <v-col cols="6">
                  <custom-label label="Proprietário"/>
                  <simple-select :items="producers"
                          v-model="payload.producer_id"
                          itemText="user.name"
                          itemValue="producer_id"
                          placeholder="Proprietário"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          @custom-change="loadFarms()"
                  />
                </v-col>

                <v-col cols="6">
                  <custom-label label="Fazenda"/>
                  <simple-select :items="farms"
                          v-model="payload.farm_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Fazenda"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          :disabled="!producerSelected"
                          required
                          @custom-change="loadFarmDetails()"
                  />
                </v-col>

                <v-col cols="6">
                  <custom-label label="Safra"/>
                  <simple-text-field placeholder="Safra"
                                      mask="harvest"
                                      :disabled="!producerSelected || payload.farm_id == null"
                                      ref="inputHarvest"
                                      v-model="farmDetails.harvestYearCalculation"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlotRecords from "@/domain/records/plot-records/plot-records";
import Farms from "@/domain/farms/farms";

import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

export default {
  name: "PlotRecordFarmAdd",
  components: {CustomLabel, SimpleTextField, SimpleSelect},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    producers: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    payload: {},

    plotRecordService: null,
    farmService: null,

    producerSelected: false,
    farms: [],
    farmDetails: {},
  }),

  methods: {
    async loadFarms() {
      const producerId = this.payload.producer_id;

      const loader          = this.$loading.show();
      this.farms            = [];
      this.farmDetails = {};
      this.payload.farm_id = null;

      await this.farmService.listByProducer(producerId).then(result => {
        result.data.map(item => {
          this.farms.push(item);
        });
      });

      this.producerSelected = true;
      loader.hide();
    },

    loadFarmDetails() {
      const farmId = this.payload.farm_id;

      const loader = this.$loading.show();
      this.farmDetails = {};
      
      this.farmService.getFarmDetails(farmId).then(result => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    close() {
      this.$emit("closeDialog");
    },

    validate() {
      if (!this.producerSelected) {
        this.$toasted.show("Selecione um produtor.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (Object.keys(this.farmDetails).length === 0) {
        this.$toasted.show("Selecione uma fazenda.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (this.$refs.inputHarvest.value === '') {
        this.$toasted.show("O campo safra é obrigatório.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      return true;
    },

    save() {
      if (this.validate()) {

        const loader = this.$loading.show();

        this.payload.harvest = this.farmDetails.harvestYearCalculation;

        this.plotRecordService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.close();

            this.$toasted.show("Registro de lotes cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },

    getAS() {
      const producer = this.farmDetails.producer;
      return producer ? producer.responsible_success_advisor.name : '';
    }
  },

  beforeMount() {
    this.payload                = PlotRecords.newData();
    this.plotRecordService = PlotRecords;
    this.farmService            = Farms;
  },
};
</script>

<style scoped>
  ::v-deep .col-farm .v-toolbar__content {
    padding: 0;
  }
</style>
