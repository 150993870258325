import http from '@/services/http.service';

const apiUrl = '/cultivars';

class CultivarsService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }
    static create(payload) {
        return http.post(`${apiUrl}`, payload);
    }
    static edit(payload) {
        return http.put(`${apiUrl}/${payload.id}`, payload);
    }
    static get(id) {
        return http.get(`${apiUrl}/${id}`);
    }
    static getByBrand(cultivarBrandId) {
        return http.get(`${apiUrl}/cultivar-brands/${cultivarBrandId}`);
    }
    static getFarmCulture(farmId,cultureId) {
        return http.get(`${apiUrl}/farms/${farmId}/cultures/${cultureId}`);
    }
    static delete(payload) {
        return http.delete(`${apiUrl}/${payload.id}`);
    }
}

export default CultivarsService;
