<template>
  <v-dialog
    :value="dialog"
    min-width="570"
    persistent
  >
    <v-card class="pa-4">
      <v-card-title class="text-h5">
        Deseja realmente excluir {{ title }}?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        
        <v-btn
          @click="closeDialog()"
          color="error"
          text
        >
          Cancelar
        </v-btn>

        <primary-button @callAction="destroy()" label="Sim, Excluir" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";

export default {
  name: "ConfirmDestroyDialog",
  components: { PrimaryButton },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    destroy() {
      this.$parent.destroy();
    },
  },
};
</script>
