import CultureService from "./cultures.service";

class Culture {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Culture.build(payload),
    });
  }

  static build(
    {
      id = '',
      name  = '',
      slug  = '',
      status = '',
      records  = {},
    }
  ) {
    return {
      id,
      name,
      slug,
      status,
      records,
    };
  }

  static newData(payload) {
    return new Culture(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      CultureService
        .list(params)
        .then((success) => {
          const {data} = success;
          const list = Object.assign({}, {...data});
          list.data = data.data.map(item => this.newData(item));
          resolve(list);
        }, (error) => {
          reject(error);
        })
    });
  }
  static all(params) {
    return new Promise((resolve, reject) => {
      CultureService
        .all(params)
        .then((success) => {
          const {data} = success;
          const list = Object.assign({}, {...data});
          list.data = data.data.map(item => this.newData(item));
          resolve(list);
        }, (error) => {
          reject(error);
        })
    });
  }
  static create(payload) {
    return new Promise((resolve, reject) => {
      CultureService.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      CultureService.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  static listManagementCultures() {
    return new Promise((resolve, reject) => {
      CultureService
        .listManagementCultures()
        .then((success) => {
          const {data} = success;
          const list = Object.assign({}, {...data});
          list.data = data.data.map(item => this.newData(item));
          resolve(list);
        }, (error) => {
          reject(error);
        })
    });
  }
  static delete(payload) {
    return new Promise((resolve, reject) => {
      CultureService
        .delete(payload)
        .then(success => {
          resolve(success);
        }, error => {
          reject(error);
        });
    });
  }
}

export default Culture;
