import http from "@/services/http.service";

const apiUrl = "/lot-records";

class PlotRecordsService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }

  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }

  static edit(payload) {
    return http.put(`${apiUrl}/${payload.id}`, payload);
  }

  static get(plotRecordId) {
    return http.get(`${apiUrl}/${plotRecordId}`);
  }

  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }

  static getMap(payload) {
    return http.get(`${apiUrl}-maps/${payload.id}`)
  }

  static getKML(payload) {
    return http.get(`${apiUrl}/maps/farms/${payload.id}`)
  }

  static getRankingNational(harvest) {
    return http.get(`${apiUrl}/ranking/national/${harvest}`)
  }

  static getRankingRegional(harvest, successAdvisorId) {
    return http.get(`${apiUrl}/ranking/regional/${harvest}/${successAdvisorId}`)
  }

  static getRankingState(harvest, stateId) {
    return http.get(`${apiUrl}/ranking/state/${harvest}/${stateId}`)
  }

  static getFarmRankingNational(farmId, harvest) {
    return http.get(`${apiUrl}/farm-ranking/${farmId}/national/${harvest}`)
  }

  static getFarmRankingRegional(farmId, harvest) {
    return http.get(`${apiUrl}/farm-ranking/${farmId}/regional/${harvest}`)
  }

  static getFarmRankingState(farmId, harvest) {
    return http.get(`${apiUrl}/farm-ranking/${farmId}/state/${harvest}`)
  }

  static getFarmHistory(farmId, payload, filters) {
    return http.get(`${apiUrl}/farm/${farmId}/productivity-history`,
      {
        params: {
          harvests: [...payload],
          ...filters
        },
      })
  }
}

export default PlotRecordsService;
