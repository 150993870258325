import http from "@/services/http.service";

const apiUrl = "/cultures";

class CulturesService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }
  static all(params) {
    return http.get(`${apiUrl}-all`, { params });
  }
  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }
  static edit(payload) {
    return http.put(`${apiUrl}/${payload.id}`, payload);
  }
  static listManagementCultures() {
    return http.get(`${apiUrl}-management`);
  }
  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }
}

export default CulturesService;
