<template>
  <v-data-table
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
      :headers="headers"
      :items="data"
      :items-per-page="itemsPerPage ? itemsPerPage : 10"
      :search="search"
      :loading="fetching"
      :loading-text="objectName ?
      'Carregando dados de ' + objectName + ', por favor aguarde...' :
      'Carregando dados, por favor aguarde...'"
      :class="getClass()"
      sort-by="id"
      no-data-text="Não foram encontrados registros."
      no-results-text="Nenhum registro bate com a busca."
  >

    <template v-slot:item.id="{ item }">
      #{{ item.id }}
    </template>

    <template v-slot:item.producer.id="{ item }">
      #{{ item.producer.id }}
    </template>

    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
      <slot :name="name" v-bind="item"></slot>
    </template>

    <template v-slot:no-data>
      <div class="pa-4">
        <p v-if="!loadingAgain">Não foram encontrados dados de {{ objectName }}.</p>
        <v-btn v-if="showLoadAgain === false ? showLoadAgain : true" :loading="loadingAgain" @click="loadAgain" color="primary"> TENTAR NOVAMENTE</v-btn>
      </div>
    </template>

    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }}-{{ items.pageStop }} de {{ items.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DataTable",
  props:
      [
        'objectName',
        'headers',
        'fetching',
        'search',
        'data',
        'noOutline',
        'itemsPerPage',
        'showLoadAgain'
      ],
  data() {
    return {
      loadingAgain: false,
    }
  },
  methods: {
    loadAgain() {
      this.loadingAgain = true;
      this.$emit('loadAgain');

      setTimeout(() => this.loadingAgain = false, 2000);
    },
    getClass(){
      let classList= "pt-6 rounded-xl "
      if (!this.noOutline)
        classList+= "px-6 yellow-border"
      
      return classList;
    }
  }
}
</script>