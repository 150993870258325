import http from "@/services/http.service";

const apiUrl = "/farms";

class FarmsService {
  static listByProducer(producerId) {
    return http.get(`${apiUrl}/producers/${producerId}`);
  }

  static getFarmDetails(farmId) {
    return http.get(`farm-details/${farmId}`);
  }

  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }

  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }

  static edit(id, payload) {
    return http.post(`${apiUrl}/${id}`, payload);
  }

  static get(id) {
    return http.get(`${apiUrl}/${id}`);
  }

  static toggle(id) {
    return http.get(`${apiUrl}/toggle-status/${id}`);
  }

  static getBiggestFarm() {
    return http.get('farm-biggest-size');
  }

  static getBiggestFarmByProducer(id) {
    return http.get(`farm-biggest-size/producers/${id}`);
  }

  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }
}

export default FarmsService;
