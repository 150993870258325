<template>
  <v-btn
    :class="`text-decoration-underline text-capitalize ${margin0 ? '' : 'custom-text-button'} ${this.noMargin ? '' : ' mt-3'}`"
    @click="callAction()" :color="this.color" text>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "TextButton",
  props: {
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    margin0: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    callAction() {
      this.$emit('callAction');
    }
  },
}
</script>

<style scoped>
.custom-text-button {
  margin-left: -16px !important;
}
</style>