<template>
  <v-btn
      class="py-4 font-weight-bold text-capitalize rounded-lg custom-button"
      @keyup.enter="callAction()"
      @click="callAction()"
      color="primary"
      :type="isSubmit ? 'submit' : ''"
      depressed
      large
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    callAction() {
      if (!this.isSubmit) {
        this.$emit('callAction');
      }
    }
  },
}
</script>

<style scoped>
  .custom-button {
    border-radius: 10px !important;
    padding: 20px 30px !important;
  }
</style>