import FarmsService from "./farms.service";

class Farm {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Farm.build(payload),
        });
    }

    static build(
        {
            id = '',
            name = '',
            address = '',
            city = '',
            zip_code = '',
            complement = '',
            farm_size = '',
            producer_id = '',
            producer = {},
            state = {},
            fields = {},
            state_id = '',
            map = {},
            is_active = false,
            harvestYearCalculation = '',
            region = '',
            sub_region = '',
            file = {}
        }
    ) {
        return {
            id,
            name,
            address,
            city,
            zip_code,
            complement,
            farm_size,
            producer_id,
            producer,
            state,
            fields,
            state_id,
            map,
            is_active,
            harvestYearCalculation,
            region,
            sub_region,
            file
        };
    }

    static newData(payload) {
        return new Farm(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            FarmsService
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            FarmsService
                .create(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static edit(id, payload = {}) {
        return new Promise((resolve, reject) => {
            FarmsService
                .edit(id, payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            FarmsService
                .toggle(id)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static listByProducer(producerId) {
        return new Promise((resolve, reject) => {
            FarmsService
                .listByProducer(producerId)
                .then(
                    success => {
                        const farms = success;
                        farms.data = farms.data.data.map(item => this.newData(item));
                        resolve(farms);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getFarmDetails(farmId) {
        return new Promise((resolve, reject) => {
            FarmsService
                .getFarmDetails(farmId)
                .then(
                    success => {
                        const farm = success;
                        farm.data = this.newData(farm.data.data);

                        resolve(farm);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getBiggestFarm() {
        return new Promise((resolve, reject) => {
            FarmsService
                .getBiggestFarm()
                .then(
                    success => {
                        const farm = success;
                        farm.data = farm.data.data ? this.newData(farm.data.data) : null;

                        resolve(farm);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getBiggestFarmByProducer(id) {
        return new Promise((resolve, reject) => {
            FarmsService
                .getBiggestFarmByProducer(id)
                .then(
                    success => {
                        const farm = success;
                        farm.data = farm.data.data ? this.newData(farm.data.data) : null;

                        resolve(farm);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static delete(payload) {
        return new Promise((resolve, reject) => {
            FarmsService
                .delete(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }
}

export default Farm;
