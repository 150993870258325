import Cultivars from "./cultivars.service";

class Cultivar {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Cultivar.build(payload),
    });
  }

  static build({
    id = "",
    name = "",
    technology = "",
    maturation_group = "",
    fertility_requirement = "",
    growth_habit = "",
    pms = "",
    is_active = "",
    cultivar_brand_id,
    cultivar_brand = {},
    created_at = "",
    updated_at = "",
    culture =  {},
    culture_id = "",
  }) {
    return {
      id,
      name,
      technology,
      maturation_group,
      fertility_requirement,
      growth_habit,
      pms,
      is_active,
      cultivar_brand_id,
      cultivar_brand,
      created_at,
      updated_at,
      culture,
      culture_id
    };
  }

  static newData(payload) {
    return new Cultivar(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      Cultivars.list(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      Cultivars.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      Cultivars.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static getByBrand(cultivarBrandId) {
    return new Promise((resolve, reject) => {
      Cultivars
      .getByBrand(cultivarBrandId)
      .then(
        success => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }

  static getFarmCulture(farmId,cultureId) {
    return new Promise((resolve, reject) => {
      Cultivars
      .getFarmCulture(farmId,cultureId)
      .then(
        success => {
          const  {data}  = success;
          
          resolve(data.data);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      Cultivars
      .delete(payload)
      .then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default Cultivar;
