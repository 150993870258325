<template>
  <span
      class="pa-0 font-weight-medium text-sm-left"
      :class="!noMargin? 'mb-8':''"
  >
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "CustomLabel",
  props: {
    label: {
      type: String,
      required: true,
    },
    noMargin:{
      type:Boolean,
      require:false,
    }
  }
}
</script>

<style scoped>
  span {
    font-size: 15px;
    color: #212020;
    opacity: 1;
    line-height: 30px;
  }
</style>