<template>
  <v-select
    v-model="currentValue"
    dense
    outlined
    hide-details="auto"
    class="custom-input"
    no-data-text="Nenhum resultado encontrado"
    :items="items"
    :placeholder="placeholder"
    :disabled="disabled"
    :return-object="returnObject"
    :item-text="itemText"
    :item-value="itemValue"
    :height="height"
    :menu-props="menuProps"
    :rules="rules"
    :multiple="multiple"
    @change="onChange()"
    single-line
    :loading="loading"
  />
</template>

<script>
export default {
  name: "SimpleSelect",
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemText: {
      required: false,
    },
    itemValue: {
      required: false,
    },
    returnObject: {
      type: Boolean,
      value: false,
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '48px'
    },
    menuProps: {
      type: Object,
      required: false,
    },
    rules: {
      type: Array,
    },
    loading:{
      type: Boolean,
      default: false,
    },
    multiple:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value ?? "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('custom-change');
    }
  }
};
</script>

<style>
.custom-input {
  border-radius: 15px !important;
}

.custom-input .v-select__selection--comma {
  overflow: clip !important;
  text-overflow: ellipsis !important;
}

.custom-input input {
  padding: 0 10px !important;
}

.custom-input fieldset {
  border: 1px solid #e3e3e3;
}

.v-menu__content {
  max-height: 215px !important;
}
</style>